import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ServiceCard from './ServiceCard'

/*Images Imports*/
import fans from '../images/pic 1.jpg'
import smokefans from '../images/pic 12.jpg'
import three from '../images/pic 14.jpg'
import four from '../images/pic7.jpg'
import skylights from '../images/skylights.jpg'
import smoke_curtain from '../images/smoke curtain.jpg'
import waterproofing from '../images/waterproofing.jpg'
import windfans from '../images/wind driven fans.jpg'
import controlpanel from '../images/control panel.jpg'
import soakerpanel from '../images/soakerpanel.jpg'

export default function Services() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        document.title = 'Smoke Ventilators Installation, Smoke Fans Installation, Wind Driven Fans Installation, Fire & Smoke Curtains Installation and Maintainence';
    }, []);

    return (
        <>
            <div className="service-container">
                <ServiceCard isLoading={isLoading} image={fans} carousel={true} otherImage={three} anotherImage={four} name="SMOKE VENTILATORS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={smokefans} name="ROOF MOUNTED SMOKE FANS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={skylights} name="SKYLIGHTS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={smoke_curtain} name="SMOKE CURTAINS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={waterproofing} name="WATER PROOFING" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={windfans} name="WIND DRIVEN FANS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={controlpanel} name="CONTROL PANELS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />
                <ServiceCard isLoading={isLoading} image={soakerpanel} name="SOAKER PANELS" desc="Some quick example text to build on the card title and make up the bulk of the card's content." />

            </div>

            <div className="pre-footer d-flex justify-content-center align-items-center text-center" style={{ backgroundColor: 'white', color: '#bd2624', height: '10vh', boxShadow: '10px 10px 10px 10px rgba(0,0,0,0.4)' }}>
                <Link to="/contact-us" style={{ textDecoration: 'none' }}><h5>Get in touch with us for more information!</h5></Link>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react';

export default function Careers() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [submitted, setSubmitted] = useState(false);
    const key = process.env.REACT_APP_API_KEY;
    const handleSubmit = async () => {
        // Check if already submitted to prevent multiple submissions
        if (submitted) {
            return;
        }

        const pdfFile = document.getElementById('pdfInput').files[0];

        const formData = new FormData();
        formData.append('pdfFile', pdfFile);

        try {
            const response = await fetch(`${key}send-resume`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                alert('Resume submitted successfully!');
                setSubmitted(true);
            } else {
                throw new Error('Error uploading file:', response.statusText);
            }
        } catch (error) {
            console.error('Error uploading file:', error.message);
        }
    };

    return (
        <div className="careers-container">
            <h1>Career Opportunities</h1>
            <h6>If you're interested in joining our team, you can submit your resume for future consideration.</h6>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '2vh' }}>
                <input type="file" id="pdfInput" name="pdfInput" accept="application/pdf" required />
                <button className="btn btn-success" onClick={handleSubmit} disabled={submitted}>
                    {submitted ? 'Submitted' : 'Submit Your Resume'}
                </button>
            </div>

            <p>If you have any questions or encounter any issues while applying, please contact us at khairalbaddah@gmail.com or call at +971 52 272 6317, +971 54 991 8375.</p>

        </div>
    );
}

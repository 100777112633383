import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import Draggable from 'react-draggable';

export default function CustomerBot() {
    const [showBot, setShowBot] = useState(false);
    const [showBotMessage, setShowBotMessage] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isBotTyping, setIsBotTyping] = useState(false);
    const [menuOptions, setMenuOptions] = useState([]);
    const messagesEndRef = useRef(null);
    const key = process.env.REACT_APP_API_KEY;

    const tod = new Date().getHours();
    let greet = "";
    if (tod > 11 && tod < 16) {
        greet = "Good Afternoon!"
    }
    else if (tod > 15 && tod <= 23) {
        greet = "Good Evening!"
    }
    else if (tod > 0) {
        greet = "Good Morning!"
    }

    const openLinkInNewTab = (e, link) => {
        e.preventDefault();
        window.open(link, '_blank');
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        setMessages([
            { text: `${greet} How can I help you?`, sender: 'bot' },
        ])
        generateMenuOptions();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const triggerHeight = 500;
        if (scrollY > triggerHeight) {
            setShowBotMessage(true);
        }
    };

    const toggleBot = () => {
        setShowBot(!showBot);
    };

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleSendMessage = async () => {
        if (inputText.trim() !== '') {
            const userMessage = { text: inputText, sender: 'user' };
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            setInputText('');
            setIsBotTyping(true);

            await new Promise(resolve => setTimeout(resolve, 1500));

            await fetch(`${key}message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ message: inputText })
            }).then(response => response.json())
                .then(data => {
                    setIsBotTyping(false);
                    const botMessage = { text: data.message, sender: 'bot' };

                    setMessages((prevMessages) => [...prevMessages, botMessage]);

                    if (data.message.toLowerCase().includes("contact")) {
                        const redirectLink = <Link style={{ textDecoration: 'none' }} to="/contact-us">Contact us form <i className="fa-solid fa-up-right-from-square"></i></Link>
                        const botMessage2 = { text: redirectLink, sender: 'bot' };
                        setMessages((prevMessages) => [...prevMessages, botMessage2]);
                    }

                    if (data.message.toLowerCase().includes("whatsapp")) {
                        const whatsappLink = <a style={{ textDecoration: 'none' }} href="https://wa.me/+917006152334">Whatsappp Chat <i className="fa-solid fa-up-right-from-square"></i></a>
                        const botMessage = { text: whatsappLink, sender: 'bot' };
                        setMessages((prevMessages) => [...prevMessages, botMessage]);
                    }

                    if (data.message.toLowerCase().includes("career")) {
                        const careerLink = <Link style={{ textDecoration: 'none' }} to="/careers">Careers Page <i className="fa-solid fa-up-right-from-square"></i></Link>
                        const botMessage = { text: careerLink, sender: 'bot' };
                        setMessages((prevMessages) => [...prevMessages, botMessage]);
                    }

                    generateMenuOptions();
                })
                .catch(error => {
                    console.error('Error:', error);
                    setIsBotTyping(false);
                    const errorMessage = { text: "Oops! Something went wrong.", sender: 'bot' };
                    setMessages((prevMessages) => [...prevMessages, errorMessage]);
                });
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };

    const generateMenuOptions = () => {
        const options = ["Installation", "Services", "About Us", "Contact Us", "Careers", "Chat with Us"];
        const menuOptions = options.map((option) => ({
            text: option,
            onClick: () => handleMenuReply(option)
        }));
        setMenuOptions(menuOptions);
    };

    const handleMenuReply = async (menuItem) => {
        setIsBotTyping(true);
        const userMessage = { text: menuItem, sender: 'user' };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        switch (menuItem) {
            case "Services":
                let replyMessage1 = "We provide complete installation services for Roof warehouse related activities including roof sheet cutting, water proofing for sandwich panels, soaker panels, flashings, installation of switch gear control panels etc.";

                await new Promise(resolve => setTimeout(resolve, 1500));
                const botMessage1 = { text: replyMessage1, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage1]);
                setIsBotTyping(false);

                let replyMessage2 = "We are also specialized in installation of wall mounted fans, smoke dampers, louvres, sand trap louvres, gravity shutters etc. We provide Annual Maintenance services (AMC) for ventilation & smoke fans, smoke ventilators & dampers, Louvres etc."
                setIsBotTyping(true);

                await new Promise(resolve => setTimeout(resolve, 1000));
                const botMessage2 = { text: replyMessage2, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage2]);
                setIsBotTyping(false);

                break;
            case "About Us":
                let replyMessage3 = "Khair Al Baddah Electromechanical Equipment Installation & Maintenance is a specialist in installation & maintenance of roof mounted Smoke Fans, Wind Driven Fans, HVLS Fans, Skylights, Smoke Ventilators, Fire & Smoke Curtains."

                await new Promise(resolve => setTimeout(resolve, 1500));
                const botMessage3 = { text: replyMessage3, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage3]);
                setIsBotTyping(false);

                let replyMessage4 = "We are actively involved in executing installation & maintenance projects for Warehouses, Factories, Food industries, Air-conditioned warehouse in UAE."
                setIsBotTyping(true);

                await new Promise(resolve => setTimeout(resolve, 1000));
                const botMessage4 = { text: replyMessage4, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage4]);
                setIsBotTyping(false);

                break;
            case "Contact Us":
                let replyMessage5 = "You can reach us at khairalbaddah@gmail.com or call us at +971522726317, +97154991837. You can even fill up our contact form as well.";
                let redirect = <Link style={{ textDecoration: 'none' }} to='/contact-us'>Contact us form <i className="fa-solid fa-up-right-from-square"></i></Link>
                await new Promise(resolve => setTimeout(resolve, 1500));
                const botMessage5 = { text: replyMessage5, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage5]);
                const botMessageredirect = { text: redirect, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessageredirect]);
                setIsBotTyping(false);
                break;
            case "Installation":
                let replyMessage6 = "We offer following types of Installations:-"
                let replyMessage6a = "1) Smoke vents"
                let replyMessage6b = "2) Smoke extract fans"
                let replyMessage6c = "3) Wind driven fans"
                let replyMessage6d = "4) Smoke Curtains"

                await new Promise(resolve => setTimeout(resolve, 1500));
                const botMessage6 = { text: replyMessage6, sender: 'bot' };
                const botMessage6a = { text: replyMessage6a, sender: 'bot' };
                const botMessage6b = { text: replyMessage6b, sender: 'bot' };
                const botMessage6c = { text: replyMessage6c, sender: 'bot' };
                const botMessage6d = { text: replyMessage6d, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage6]);
                setMessages((prevMessages) => [...prevMessages, botMessage6a]);
                setMessages((prevMessages) => [...prevMessages, botMessage6b]);
                setMessages((prevMessages) => [...prevMessages, botMessage6c]);
                setMessages((prevMessages) => [...prevMessages, botMessage6d]);
                setIsBotTyping(false);
                break;

            case "Careers":
                let replyMessageCareer = "You can send your resume on our careers page to apply."
                let CareerLink = <Link style={{ textDecoration: 'none' }} to='/careers'>Careers Page <i className="fa-solid fa-up-right-from-square"></i></Link>
                await new Promise(resolve => setTimeout(resolve, 1500));
                const botMessageCareer = { text: replyMessageCareer, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessageCareer]);
                const botMessageCareerLink = { text: CareerLink, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessageCareerLink]);
                setIsBotTyping(false);
                break;

            case "Chat with Us":
                let replyMessageWhatsApp = "You can start a WhatsApp chat with us by clicking the link below:";
                let whatsappLink = "https://wa.me/+917006152334";
                const botMessageWhatsApp = { text: replyMessageWhatsApp, sender: 'bot', link: whatsappLink };
                setMessages((prevMessages) => [...prevMessages, botMessageWhatsApp]);
                setIsBotTyping(false);
                break;

            default:
                let replyMessage7 = "Invalid option selected";
                await new Promise(resolve => setTimeout(resolve, 1500));
                const botMessage7 = { text: replyMessage7, sender: 'bot' };
                setMessages((prevMessages) => [...prevMessages, botMessage7]);
                break;
        }

    };

    return (
        <>
            <>
                {!showBot && (
                    <Draggable bounds="parent" cancel=".botButton">
                        <div className="fixed-container">
                            <button className="botButton" onClick={toggleBot}>
                                <i className="fa-sharp fa-solid fa-headset"></i>
                            </button>
                            {showBotMessage && (
                                <div className="bubble-message">
                                    Hi! Need Help?
                                </div>
                            )}
                        </div>
                    </Draggable>
                )}
            </>

            {showBot && (
                <div className="chat-container" >
                    <div className="text-center bot-header fixed-top">
                        <i className="fa-solid fa-headset mx-1"></i> Customer Assistance Bot
                        <button className="closeBot" onClick={toggleBot}><i className="fa-solid fa-xmark"></i></button>
                    </div>
                    <div className="message-container">
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                className={`message ${message.sender === 'user' ? 'user-message' : 'other-message'}`}
                            >
                                {message.link ? (
                                    <a href={message.link} target="_blank" rel="noopener noreferrer" onClick={(e) => openLinkInNewTab(e, message.link)}>
                                        {message.text}
                                    </a>
                                ) : (
                                    message.text
                                )}
                                <div ref={messagesEndRef} />
                            </div>
                        ))}

                        {isBotTyping && (
                            <div className="message bot-message typing-animation"><span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></div>
                        )}
                        {!isBotTyping && (
                            <div className="menu-options">
                                {menuOptions.map((option, index) => (
                                    <div key={index} className="menu-option" onClick={option.onClick}>
                                        {option.text}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>



                    <div className="chat-box">
                        <div className="input-container">
                            <input type="text" value={inputText} onChange={handleInputChange} onKeyPress={handleKeyPress} placeholder="Type your message..." />
                            <button onClick={handleSendMessage}>Send</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

import React, { useState, useEffect } from 'react'
import ClientCard from './ClientCard'
import orbitindia from '../images/consultants/orbitindia.png'
import chawla from '../images/consultants/chawla.png'
import acc from '../images/consultants/acc.webp'
import designhouse from '../images/consultants/designhouse.png'
import emiratessas from '../images/consultants/emiratessas.png'
import iconic from '../images/consultants/iconic.jpeg'
import east from '../images/consultants/east.jpeg'
import designconcepts from '../images/consultants/designconcepts.png'
import injaz from '../images/consultants/injaz.png'
import ecogreen from '../images/consultants/ecogreen.jpeg'
import align from '../images/consultants/align.png'
import winner from '../images/consultants/winner.png'


export default function Consultants() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.title = 'Consultants - Khair Al Baddah | Smoke Fans, Wind Driven Fans, Smoke Ventilators, Fire & Smoke Curtains Installation and Maintainence';
  }, []);

  return (
    <div className="consultant-container">
      <ClientCard isLoading={isLoading} img={orbitindia} title="Orbit Engineering Consultancy" />
      <ClientCard isLoading={isLoading} img={chawla} title="Chawla Architectural & Consulting Engineers" />
      <ClientCard isLoading={isLoading} img={acc} title="Architectural Corner Consultants" />
      <ClientCard isLoading={isLoading} img={designhouse} title="Design House Consultant" />
      <ClientCard isLoading={isLoading} img={emiratessas} title="Emirates SAS Engineering Consultant" />
      <ClientCard isLoading={isLoading} img={iconic} title="ICONIC Architectural & Engineering Consultants" />
      <ClientCard isLoading={isLoading} img={east} title="East Engineering Consultancy" />
      <ClientCard isLoading={isLoading} img={designconcepts} title="Design Concepts Engineering" />
      <ClientCard isLoading={isLoading} img={winner} title="Winner Holistic Consultants" />
      <ClientCard isLoading={isLoading} img={ecogreen} title="Ecogreen Architectural & Engineering Consultants" />
      <ClientCard isLoading={isLoading} img={injaz} title="Injaz Zone Consultant" />
      <ClientCard isLoading={isLoading} img={align} title="Align Engineering Consultancy" />
      <ClientCard isLoading={isLoading} title="Design Engineering Consultant" />
    </div>
  )
}

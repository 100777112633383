import React, { useEffect, useState } from 'react';

export default function ContactUs() {
  const key = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    document.title = 'Contact Us - Khair Al Baddah | Smoke Fans, Wind Driven Fans, Smoke Ventilators, Fire & Smoke Curtains Installation and Maintainence';
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    enquiry: 'Enquiry About',
    message: '',
    subscribe: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${key}send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      alert('Email sent successfully');
      setFormData({
        name: '',
        email: '',
        phone: '',
        enquiry: 'Enquiry About',
        message: '',
        subscribe: false
      });
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Error sending email');
    }
  };
  return (
    <>
      <div className="contact-container fade-in">
        <h1 className="text-center mb-4" style={{ color: 'white' }}><b>Contact Us</b></h1>
        <div className='form-container'>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Name *</label>
              <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email *</label>
              <input type="email" className="form-control" id="email" name="email" value={formData.email} onChange={handleChange} aria-describedby="emailHelp" required />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Phone No *</label>
              <input type="phone" className="form-control" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="dropdown mb-3">
              <select className="btn dropdown-toggle" style={{ border: '1px solid gray' }} name="enquiry" value={formData.enquiry} onChange={handleChange}>
                <option selected value="selectone">Enquiry About</option>
                <option value="Smoke Ventilators">Smoke Ventilators</option>
                <option value="Roof Mounted Smoke Fans">Roof Mounted Smoke Fans</option>
                <option value="Skylights">Skylights</option>
                <option value="Smoke Curtains">Smoke Curtains</option>
                <option value="Water Proofing">Water Proofing</option>
                <option value="Wind Driven Fans">Wind Driven Fans</option>
                <option value="Control Panels">Control Panels</option>
                <option value="Soaker Panels">Soaker Panels</option>
              </select>
            </div>
            <div className="form-floating mb-3">
              <textarea className="form-control" placeholder="Leave a comment here" name="message" id="floatingTextarea2" value={formData.message} onChange={handleChange} style={{ height: '100px' }} required></textarea>
              <label htmlFor="floatingTextarea2">Write to Us... *</label>
            </div>
            <div className="mb-3 form-check">
              <input type="checkbox" className="form-check-input" id="exampleCheck1" name="subscribe" value={formData.subscribe} onChange={handleChange} />
              <label className="form-check-label" htmlFor="exampleCheck1">Sign up for emails and offers from us!</label>
            </div>
            <button type="submit" className="submit-btn btn">Submit</button>
          </form>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import notfound from '../images/notfound.png'

export default function NotFound() {
    return (
        <div className="container text-center mb-5" style={{ display: 'flex', flexDirection: 'column', paddingTop: '20vh', alignContent: 'center', alignItems: 'center' }}>
            <img src={notfound} style={{ width: '50vh' }}></img>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    )
}

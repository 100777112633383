import React from 'react'

export default function Footer() {
    return (
        <div className="footer">
            <div className="social-media-icons">
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/khairalbaddah"><i className="fa-brands fa-instagram"></i></a>
                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/khair-al-baddah-044922275/"><i className="fa-brands fa-linkedin"></i></a>
            </div>
            <div className="details">
                <div className="icon">
                    <i className="fa-solid fa-envelope"></i>
                    <h5 className="mx-2" style={{ marginTop: '1vh' }}> khairalbaddah@gmail.com</h5>
                </div>
                <div className="icon">
                    <i className="fa-solid fa-location-dot"></i>
                    <h5 className="mx-2" style={{ marginTop: '1vh' }}>Al Qusais 2, Dubai - U.A.E.</h5>
                </div>
                <div className="icon">
                    <i className="fa-solid fa-phone"></i>
                    <h5 className="mx-1" style={{ marginTop: '0.5vh' }}> +971 52 272 6317, +971 54 991 8375</h5>
                </div>
            </div>
        </div>
    )
}

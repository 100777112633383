import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import ProjectDetails from './components/ProjectDetails';
import Clients from './components/Clients';
import Consultants from './components/Consultants';
import Services from './components/Services';
import ContactUs from './components/ContactUs';
import Contractors from './components/Contractors';
import CustomerBot from './components/CustomerBot';
import Careers from './components/Careers';

import NotFound from './components/NotFound';

export default function App() {
  return (
    <Router>
      <Navbar title="Khair Al Baddah" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services-offered" element={<Services />} />
        <Route path="/project-details" element={<ProjectDetails />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/consultants" element={<Consultants />} />
        <Route path="/contractors" element={<Contractors />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <CustomerBot />
    </Router>
  );
}

import React, { useState, useEffect } from 'react'
import ContractorsImg from './ContractorsImg'
import align from '../images/contractors/align.png'
import zener from '../images/contractors/zener.png'
import loyal from '../images/contractors/loyal.png'
import summer from '../images/contractors/summer.jpg'
import bin from '../images/contractors/bin.jpg'
import alzarooni from '../images/contractors/alzarooni.png'
import philco from '../images/contractors/philco.png'
import veristar from '../images/contractors/verista.png'
import amana from '../images/contractors/amana.png'
import surti from '../images/contractors/surti.png'
import ashiyana from '../images/contractors/ashiyana.jpg'
import ifps from '../images/contractors/ifps.png'
import ksk from '../images/contractors/ksk.jpg'
import buraq from '../images/contractors/buraq.jpeg'
import leminar from '../images/contractors/leminar.png'
import lucent from '../images/contractors/lucent.jpg'
import citynights from '../images/contractors/citynights.png'
import almasood from '../images/contractors/almasood.png'
import hira from '../images/contractors/hria.png'

export default function Contractors() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.title = 'Contractors - Khair Al Baddah | Smoke Fans, Wind Driven Fans, Smoke Ventilators, Fire & Smoke Curtains Installation and Maintainence';
  }, []);

  return (
    <div className="contractor-container fade-in">
      <ContractorsImg isLoading={isLoading} image={align} />
      <ContractorsImg isLoading={isLoading} image={zener} />
      <ContractorsImg isLoading={isLoading} image={loyal} />
      <ContractorsImg isLoading={isLoading} image={summer} />
      <ContractorsImg isLoading={isLoading} image={bin} />
      <ContractorsImg isLoading={isLoading} image={alzarooni} />
      <ContractorsImg isLoading={isLoading} image={philco} />
      <ContractorsImg isLoading={isLoading} image={veristar} />
      <ContractorsImg isLoading={isLoading} image={amana} />
      <ContractorsImg isLoading={isLoading} image={surti} />
      <ContractorsImg isLoading={isLoading} image={ashiyana} />
      <ContractorsImg isLoading={isLoading} image={ifps} />
      <ContractorsImg isLoading={isLoading} image={ksk} />
      <ContractorsImg isLoading={isLoading} image={buraq} />
      <ContractorsImg isLoading={isLoading} image={leminar} />
      <ContractorsImg isLoading={isLoading} image={lucent} />
      <ContractorsImg isLoading={isLoading} image={citynights} />
      <ContractorsImg isLoading={isLoading} image={almasood} />
      <ContractorsImg isLoading={isLoading} image={hira} />
    </div>
  )
}

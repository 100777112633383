import React from 'react'

export default function ContractorsImg(props) {
    return (
        <div className="card">
            <img src={props.image} className="contractor-img fade-in" alt="..." style={{ overflow: 'hidden' }} />
        </div>

    );
};

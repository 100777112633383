import React, { useEffect } from 'react'
export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Home - Khair Al Baddah | Smoke Fans, Wind Driven Fans, Smoke Ventilators, Fire & Smoke Curtains Installation and Maintainence';
  }, []);
  return (
    <>
      <div className="first">
        <div>
          <h1 className="header1 fade-in"><b>Seamless Solutions,<br /> Solid Support.</b></h1> <br />
          <h3 className="application-header fade-in"><b>Electro Mechanical Equipment <br />Installation and Maintenance EST</b></h3>
        </div>
      </div>

      <div className="icon-container">
        <div className="application fade-in">
          <div className="icon mx-5">
            <i className="fa-solid fa-warehouse mx-3"></i>
            <h3>Warehouse</h3>
          </div>
          <div className="icon mx-5">
            <i className="fa-solid fa-house-chimney mx-3"></i>
            <h3>Residential</h3>
          </div>
          <div className="icon mx-5">
            <i className="fa-solid fa-industry mx-3"></i>
            <h3>Factories</h3>
          </div>
        </div>
      </div>

      <div className="intro">
        <div className="intro1 fade-in">
          Khair Al Baddah Electromechanical Equipment Installation &
          Maintenance is a specialist in installation & maintenance of
          roof mounted Smoke Fans, Wind Driven Fans, HVLS Fans,
          Skylights, Smoke Ventilators, Fire & Smoke Curtains.
          <br />
          <br />
          We are actively involved in executing installation & maintenance
          projects for Warehouses, Factories, Food industries, Air-conditioned
          warehouse in UAE.
          <br />
          <br />
          We provide complete installation services for Roof warehouse
          related activities including roof sheet cutting, water proofing for
          sandwich panels, soaker panels, flashings, installation of switch gear
          control panels etc.
          <br />
          <br />
          We are also specialized in installation of wall mounted fans, smoke
          dampers, louvres, sand trap louvres, gravity shutters etc.
          We provide Annual Maintenance services (AMC) for ventilation &
          smoke fans, smoke ventilators & dampers, Louvres etc.
        </div>

        <div className="intro2">
          <b>Specialist in installation & maintenance: -</b><p />
          <i className="fa-solid fa-fan"></i> Roof Mounted Smoke Fans<p />
          <i className="fa-solid fa-fan"></i> Wind Driven Fans<p />
          <i className="fa-solid fa-fan"></i> HVLS Fans<p />
          <i className="fa-solid fa-lightbulb"></i> Skylights<p />
          <i className="fa-solid fa-water"></i> Water Proofing Works<p />
          <i className="fa-solid fa-smog"></i> Smoke Ventilators<p />
          <i className="fa-solid fa-fire"></i> Fire Smoke Curtain<p />
        </div>
      </div>
      <div className="text-center mb-5">
        <a className="brochure-link" target="_blank" rel="noreferrer" href="https://www.khairalbaddahuae.com/khair_al_baddah_profile.pdf"><h5> <i className="fa-sharp fa-regular fa-file-pdf"></i> Download Brochure</h5 > </a>
      </div >
    </>
  )
}

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.png';

export default function Navbar(props) {
    const location = useLocation();

    const isActive = (pathname) => {
        return location.pathname === pathname ? 'active' : '';
    };

    return (
        <>
            <nav className={"navbar navbar-expand-lg bg-body-tertiary"}>
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand d-flex align-items-center">
                        <img src={logo} className="logo" alt="Logo Not Loaded" />
                        <h2 className="brand-name mb-0 ml-2">Khair Al Baddah</h2>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <div className="navbar-nav mx-auto">
                            <Link
                                className={`nav-link mx-2 ${isActive('/')}`}
                                to="/"
                            >
                                HOME
                            </Link>

                            <Link
                                className={`nav-link mx-2 ${isActive(
                                    '/services-offered'
                                )}`}
                                to="/services-offered"
                            >
                                SERVICES
                            </Link>

                            <Link
                                className={`nav-link mx-2 ${isActive(
                                    '/clients'
                                )}`}
                                to="/clients"
                            >
                                CLIENTS
                            </Link>

                            <Link
                                className={`nav-link mx-2 ${isActive(
                                    '/project-details'
                                )}`}
                                to="/project-details"
                            >
                                PROJECTS
                            </Link>

                            <Link
                                className={`nav-link mx-2 ${isActive(
                                    '/consultants'
                                )}`}
                                to="/consultants"
                            >
                                CONSULTANTS
                            </Link>

                            <Link
                                className={`nav-link mx-2 ${isActive(
                                    '/contractors'
                                )}`}
                                to="/contractors"
                            >
                                CONTRACTORS
                            </Link>

                            <Link
                                className={`nav-link mx-2 ${isActive(
                                    '/careers'
                                )}`}
                                to="/careers"
                            >
                                CAREERS
                            </Link>
                        </div>
                        <div className="navbar-nav ms-auto">
                            <Link
                                className={`contactusbutton mx-2 ${isActive(
                                    '/contact-us'
                                )}`}
                                to="/contact-us" style={{ textDecoration: 'none' }}
                            >
                                CONTACT US
                            </Link>
                        </div>
                    </div>
                </div >
            </nav >
        </>
    );
}

import React, { useEffect } from 'react'

export default function ProjectDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Project Details - Khair Al Baddah | Smoke Fans, Wind Driven Fans, Smoke Ventilators, Fire & Smoke Curtains Installation and Maintainence';
  }, []);

  return (
    <>
      <div className="project-container fade-in">
        <div className="container projects ">
          <h4 className="my-4 project-detail"><b>Huhtamaki packaging factory at Ras Al Khaimah</b></h4>
          <h4 className="my-4 project-detail"><b>Air Conditioned G+M warehouse at Jafza</b></h4>
          <h4 className="my-4 project-detail"><b>Factory & Shed at DIC</b></h4>
          <h4 className="my-4 project-detail"><b>Non air conditioned factory project at Jabel</b></h4>
          <h4 className="my-4 project-detail"><b>Ali industrial-1</b></h4>
          <h4 className="my-4 project-detail"><b>11 Warehouses project at Jabel Ali</b></h4>
          <h4 className="my-4 project-detail"><b>P-147/ 10 warehouse + 12 shops at Ras Al
            khor</b></h4>
          <h4 className="my-4 project-detail"><b>Factory & service block to exist. facility at
            tecknopark</b></h4>
          <h4 className="my-4 project-detail"><b>Cold Store Warehouse M/s  best food
            company at Dip 2</b></h4>
          <h4 className="my-4 project-detail"><b>G+M cash & carry warehouse at Kizad</b></h4>
          <h4 className="my-4 project-detail"><b>G+M warehouse & office at technopark</b></h4>
          <h4 className="my-4 project-detail"><b>IC1-WRH-22A warehouse at Al Warsan</b></h4>
          <h4 className="my-4 project-detail"><b>Decor Shed 27A & 27C at UAQ</b></h4>
          <h4 className="my-4 project-detail"><b>G+M Warehouse & Office , International City
            Phase 1</b></h4>
          <h4 className="my-4 project-detail"><b>Warehouse/Office G+M at Dip 1</b></h4>
          <h4 className="my-4 project-detail"><b>Proposed G+M Warehouse / Office /
            Underground Water tank With Pump Room
            On Plot No. IC1- WRH-055, International City
            Phase 1</b></h4>
          <h4 className="my-4 project-detail"><b>PROPOSED WAREHOUSE/OFFICE G+M PLOT#:
            597-119 (P152)</b></h4>
        </div>
      </div>
    </>
  )
}

import React from 'react'


export default function ServiceCard(props) {
    if (props.isLoading) {
        return (
            <>
                <div className="card service-card" >
                    <div className="skeleton-image" style={{ width: '100%', height: '100%' }}></div>
                    <div className="card-body">
                        <div className="skeleton-text" style={{ width: '80%', height: '20px' }}></div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            {!props.carousel &&
                <div className="card service-card" >
                    < img src={props.image} className="card-img-top" alt="..." style={{ overflow: 'hidden' }} />

                    <div className="card-body">
                        <p className="card-text text-center"><b>{props.name}</b></p>
                    </div>
                </div >
            }

            {props.carousel &&
                <div className="card service-card" >
                    <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={props.image} className="card-img-top" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={props.otherImage} className="card-img-top" alt="..." />
                            </div>
                            <div className="carousel-item">
                                <img src={props.anotherImage} className="card-img-top" alt="..." />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <div className="card-body">
                        <p className="card-text text-center"><b>{props.name}</b></p>
                    </div>
                </div >
            }
        </>
    );
};

import React from 'react';

export default function ClientCard(props) {
  if (props.isLoading) {
    return (
      <div className="card mb-3 client-card">
        <div className="row g-0">
          <div className="col-md-4">
            <div className="skeleton-image" style={{ width: '100%', height: '100%', marginTop: '1vh', marginLeft: '1vh' }}></div>
          </div>
          <div className="col-md-8 d-flex align-items-center">
            <div className="card-body">
              <div className="skeleton-text" style={{ width: '100%', height: '60px' }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card mb-3 fade-in client-card">
      <div className="row g-0 align-items-center">
        {props.img && (
          <div className="col-md-4 text-center mb-3 mb-md-0">
            <img src={props.img} className="client-img img-fluid rounded-start" alt="..." />
          </div>
        )}

        <div className="col-md-8">
          <div className="card-body text-center text-md-start">
            <h5 className="client-name card-title">{props.title}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}

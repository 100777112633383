import React, { useState, useEffect } from 'react'
import ClientCard from './ClientCard'

import nishat from '../images/clients/nishat.jpeg'
import zener from '../images/clients/zener.png'
import tns from '../images/clients/tns.png'
import hira from '../images/clients/hira.png'
import bin from '../images/clients/bin.jpg'
import bfc from '../images/clients/bestfoodcompany.png'
import jaleel from '../images/clients/jaleel.png'
import lals from '../images/clients/lals.png'

export default function Clients() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.title = 'Our Clients - Khair Al Baddah | Smoke Fans, Wind Driven Fans, Smoke Ventilators, Fire & Smoke Curtains Installation and Maintainence';
  }, []);

  return (
    <>
      <div className="client-container">
        <ClientCard isLoading={isLoading} img={nishat} title="Nishat General Co. LLC" />
        <ClientCard isLoading={isLoading} img={zener} title="Zener Fire & Security FZE" />
        <ClientCard isLoading={isLoading} img={tns} title="Tahir Niazi Stores FZCO" />
        <ClientCard isLoading={isLoading} img={hira} title="Hira Industries LLC" />
        <ClientCard isLoading={isLoading} img={bin} title="Bin Dhaen Holding LLC" />
        <ClientCard isLoading={isLoading} img={bfc} title="Best Food Company" />
        <ClientCard isLoading={isLoading} img={jaleel} title="Jaleel Holdings LLC" />
        <ClientCard isLoading={isLoading} img={lals} title="Lals Retail Branch LLC" />
        <ClientCard isLoading={isLoading} title="Oilfields Supply Center Ltd" />
        <ClientCard isLoading={isLoading} title="Mr.Jiansheng Sun" />
        <ClientCard isLoading={isLoading} title="Mr Humaid Sultan Humaid Bin Muwaiza" />
        <ClientCard isLoading={isLoading} title="Berger Paints Emirates Co. LTD" />
        <ClientCard isLoading={isLoading} title="Jianguo Jin & Li Lin" />
        <ClientCard isLoading={isLoading} title="Nader Abdulaziz Najim Mohammed Ala Wadhi" />
      </div>
    </>
  )
}
